import React from 'react';
import { X } from 'lucide-react';
import VideoPlayer from './VideoPlayer';

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoId: string;
  title: string;
}

export default function VideoModal({ isOpen, onClose, videoId, title }: VideoModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80 backdrop-blur-sm">
      <div className="relative w-full max-w-5xl bg-black rounded-xl overflow-hidden shadow-2xl">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-white/80 hover:text-white bg-black/50 hover:bg-black/70 rounded-lg transition-colors z-10"
          aria-label="Close video"
        >
          <X className="w-5 h-5" />
        </button>
        
        <div className="aspect-video">
          <VideoPlayer
            videoId={videoId}
            title={title}
            onError={onClose}
          />
        </div>
      </div>
    </div>
  );
}