import React from 'react';
import type { LucideIcon } from 'lucide-react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost';
  size?: 'sm' | 'md' | 'lg';
  icon?: LucideIcon;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  className?: string;
}

export default function Button({
  children,
  variant = 'primary',
  size = 'md',
  icon: Icon,
  iconPosition = 'right',
  loading = false,
  className = '',
  ...props
}: ButtonProps) {
  const baseStyles = 'inline-flex items-center justify-center font-medium transition-all duration-200 rounded-lg';
  
  const variants = {
    primary: `relative overflow-hidden touch-manipulation select-none bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 
      dark:from-[#6B21A8] dark:via-[#4C1D95] dark:to-[#2E1065] text-white 
      shadow-[0_0_0_0_rgba(96,165,250,0)] hover:shadow-[0_0_40px_0_rgba(96,165,250,0.25)]
      dark:shadow-[0_0_0_0_rgba(147,51,234,0)] dark:hover:shadow-[0_0_60px_0_rgba(147,51,234,0.4)]
      active:scale-[0.98] font-semibold
      before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/[0.15] before:to-transparent
      before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-[800ms]
      after:absolute after:inset-0 after:bg-gradient-to-r after:from-transparent after:via-white/[0.15] after:to-transparent
      after:translate-x-[-200%] hover:after:translate-x-[200%] after:transition-transform after:duration-[800ms] after:delay-[400ms]
      dark:border dark:border-violet-500/20 dark:hover:border-violet-400/40
      dark:bg-[linear-gradient(45deg,#6B21A8,#4C1D95,#2E1065)]
      dark:[background-size:200%_auto] dark:hover:bg-right dark:animate-gradient
      dark:backdrop-blur-[2px] dark:backdrop-saturate-150
      dark:after:content-[''] dark:after:absolute dark:after:inset-0 
      dark:after:bg-[radial-gradient(circle_at_50%_50%,rgba(168,85,247,0.1),transparent_70%)]
      dark:after:opacity-0 dark:hover:after:opacity-100 dark:after:transition-opacity dark:after:duration-500`,
    secondary: 'touch-manipulation select-none bg-gray-100 dark:bg-slate-800 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-slate-700 active:bg-gray-300 dark:active:bg-slate-600 font-medium',
    outline: `relative overflow-hidden touch-manipulation select-none border-2 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 
      hover:border-blue-500 dark:hover:border-violet-400/60 font-medium
      dark:hover:border-violet-500/60 dark:hover:bg-violet-900/30
      dark:backdrop-blur-sm dark:backdrop-saturate-150
      dark:after:content-[''] dark:after:absolute dark:after:inset-0
      dark:after:bg-[radial-gradient(circle_at_50%_50%,rgba(168,85,247,0.08),transparent_70%)]
      dark:after:opacity-0 dark:hover:after:opacity-100 dark:after:transition-opacity dark:after:duration-500
      before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-blue-500/[0.1] before:to-transparent
      before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-[800ms]`,
    ghost: 'touch-manipulation select-none text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-slate-800 active:bg-gray-200 dark:active:bg-slate-700 font-medium'
  };

  const sizes = {
    sm: 'h-9 px-4 text-sm w-full sm:w-auto min-h-[48px]',
    md: 'h-11 px-6 text-base w-full sm:w-auto min-h-[48px]',
    lg: 'h-14 px-8 text-lg w-full sm:w-auto min-h-[48px]'
  };

  return (
    <button
      className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className + ' hover-lift button-glow'}`}
      disabled={loading || props.disabled}
      {...props}
    >
      {loading ? (
        <div className="flex items-center">
          <div className="w-5 h-5 border-2 border-current opacity-30 border-t-current rounded-full animate-spin mr-2" />
          <span>Loading...</span>
        </div>
      ) : (
        <span className="flex items-center">
          {Icon && iconPosition === 'left' && (
            <Icon className="w-5 h-5 mr-2 transform group-hover:scale-110 transition-transform" />
          )}
          {children}
          {Icon && iconPosition === 'right' && (
            <Icon className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" />
          )}
        </span>
      )}
    </button>
  );
}