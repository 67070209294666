import React, { useEffect, useRef } from 'react';

interface VideoPlayerProps {
  videoId: string;
  title?: string;
  onError?: () => void;
}

export default function VideoPlayer({ videoId, title = 'Video', onError }: VideoPlayerProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!videoId) return;

    // Load the YouTube IFrame API
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag?.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    // Handle API ready
    window.onYouTubeIframeAPIReady = () => {
      if (iframeRef.current) {
        new window.YT.Player(iframeRef.current, {
          events: {
            onError: (event) => {
              console.error('YouTube player error:', event);
              onError?.();
            }
          }
        });
      }
    };

    return () => {
      // Cleanup
      delete window.onYouTubeIframeAPIReady;
    };
  }, [videoId, onError]);

  return (
    <iframe
      ref={iframeRef}
      className="w-full h-full"
      src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0&modestbranding=1&autoplay=1`}
      title={title}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}